import React, { Suspense, useState, useEffect, useRef } from 'react';
import './App.css'; // Import your CSS file for the modal styles


const alertPendingOffers = () => {

    return (
            <div id="displayPendingOffers" ></div>
          );
    };

export default alertPendingOffers;
